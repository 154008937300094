<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';

import {required, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Layout from '../../layouts/main';
import {tasksChart, tasks} from './data-tasklist';

/**
 * Task-list component
 */
export default {
  setup() {
    return {v$: useVuelidate()};
  },
  page: {
    title: 'Task List',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      tasksChart,
      inprogressTasks: '',
      upcomingTasks: '',
      completedTasks: '',
      title: 'Task List',
      items: [
        {
          text: 'Tasks',
          href: '/',
        },
        {
          text: 'Task List',
          active: true,
        },
      ],
      taskList: {
        name: '',
      },
      myFiles: [],
      selected: '',
      selected2: '',
      submitted: false,
      showModal: false,
    };
  },
  validations: {
    taskList: {
      name: {
        required: helpers.withMessage('Task is required', required),
      },
    },
  },
  mounted() {
    this.isMountData();
    // all tasks
  },
  methods: {
    onFileChange(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.myFiles.push(
          require(`@/assets/images/users/${event.target.files[i].name}`),
        );
      }
    },

    handleSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.tasks.push({
        index: this.tasks.length + 1,
        taskType: this.selected,
        name: this.taskList.name,
        images: this.myFiles,
        status: this.selected2,
        checked: false,
      });
      this.isMountData();
      this.showModal = false;
      this.taskList = {};
      this.selected = '';
      this.selected2 = '';
      this.myFiles = [];

      this.submitted = false;
    },

    isMountData() {
      this.tasks = tasks;
      this.inprogressTasks = tasks.filter((t) => t.taskType === 'inprogress');
      this.upcomingTasks = tasks.filter((t) => t.taskType === 'upcoming');
      this.completedTasks = tasks.filter((t) => t.taskType === 'completed');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-start">
              <h4 class="card-title mb-4">Upcoming</h4>
              <b-button
                class="btn btn-success ms-auto"
                @click="showModal = true"
              >Add Task
              </b-button
              >
            </div>
            <b-modal v-model="showModal" hide-footer title="Add Task">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="name">Event Name</label>
                      <input
                        id="name"
                        v-model="taskList.name"
                        :class="{
                          'is-invalid': submitted && v$.taskList.name.$error,
                        }"
                        class="form-control"
                        placeholder="Insert name"
                        type="text"
                      />
                      <div
                        v-if="submitted && v$.taskList.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="v$.taskList.name.required.$message">{{
                            v$.taskList.name.required.$message
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="file">File</label>
                    <input
                      id="file"
                      class="form-control"
                      multiple
                      type="file"
                      @change="onFileChange($event)"
                    />
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      Task Type
                      <b-form-select
                        v-model="selected"
                        class="mb-3 form-select"
                      >
                        <b-form-select-option value="upcoming"
                        >Upcoming
                        </b-form-select-option
                        >
                        <b-form-select-option value="inprogress"
                        >In-progress
                        </b-form-select-option
                        >
                        <b-form-select-option value="completed"
                        >Completed
                        </b-form-select-option
                        >
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      Task Status
                      <b-form-select
                        v-model="selected2"
                        class="mb-3 form-select"
                      >
                        <b-form-select-option value="Approved"
                        >Approved
                        </b-form-select-option
                        >
                        <b-form-select-option value="Waiting"
                        >Waiting
                        </b-form-select-option
                        >
                        <b-form-select-option value="Pending"
                        >Pending
                        </b-form-select-option
                        >
                        <b-form-select-option value="Complete"
                        >Complete
                        </b-form-select-option
                        >
                      </b-form-select>
                    </div>
                  </div>
                </div>
                <div class="text-end mt-3">
                  <b-button variant="light">Close</b-button>
                  <b-button class="ms-1" type="submit" variant="success"
                  >Create event
                  </b-button
                  >
                </div>
              </form>
            </b-modal>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap align-middle mb-0">
                <tbody>
                <tr v-for="task of upcomingTasks" :key="task.index">
                  <td style="width: 40px">
                    <b-form-checkbox
                      v-model="task.checked"
                      class="form-check"
                    >
                    </b-form-checkbox>
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14 m-0">
                      <a class="text-dark" href="javascript: void(0);">{{
                          task.name
                        }}</a>
                    </h5>
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div
                        v-for="(data, index) of task.images"
                        :key="index"
                        class="avatar-group-item"
                      >
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${data}`"
                            alt=""
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                        <span
                          :class="{
                            'badge-soft-success': task.status === 'Complete',
                            'badge-soft-warning': task.status === 'Pending',
                            'badge-soft-primary': task.status === 'Approved',
                            'badge-soft-secondary': task.status === 'Waiting',
                          }"
                          class="badge rounded-pill font-size-11"
                        >{{ task.status }}</span
                        >
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-start">
              <h4 class="card-title mb-4">In Progress</h4>
              <b-button
                class="btn btn-success ms-auto"
                @click="showModal = true"
              >Add Task
              </b-button
              >
            </div>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-centered">
                <tbody>
                <tr v-for="task of inprogressTasks" :key="task.index">
                  <td style="width: 40px">
                    <b-form-checkbox
                      v-model="task.checked"
                      class="form-check"
                    >
                    </b-form-checkbox>
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14 m-0">
                      <a class="text-dark" href="javascript: void(0);">{{
                          task.name
                        }}</a>
                    </h5>
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div
                        v-for="(data, index) of task.images"
                        :key="index"
                        class="avatar-group-item"
                      >
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${data}`"
                            alt=""
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                        <span
                          :class="{
                            'badge-soft-success': task.status === 'Complete',
                            'badge-soft-warning': task.status === 'Pending',
                            'badge-soft-primary': task.status === 'Approved',
                            'badge-soft-secondary': task.status === 'Waiting',
                          }"
                          class="badge rounded-pill font-size-11"
                        >{{ task.status }}</span
                        >
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-start">
              <h4 class="card-title mb-4">Completed</h4>
              <b-button
                class="btn btn-success ms-auto"
                @click="showModal = true"
              >Add Task
              </b-button
              >
            </div>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-centered">
                <tbody>
                <tr v-for="task of completedTasks" :key="task.index">
                  <td style="width: 40px">
                    <b-form-checkbox
                      v-model="task.checked"
                      class="form-check"
                    >
                    </b-form-checkbox>
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14 m-0">
                      <a class="text-dark" href="javascript: void(0);">{{
                          task.name
                        }}</a>
                    </h5>
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div
                        v-for="(data, index) of task.images"
                        :key="index"
                        class="avatar-group-item"
                      >
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${data}`"
                            alt=""
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                        <span
                          :class="{
                            'badge-soft-success': task.status === 'Complete',
                            'badge-soft-warning': task.status === 'Pending',
                            'badge-soft-primary': task.status === 'Approved',
                            'badge-soft-secondary': task.status === 'Waiting',
                          }"
                          class="badge rounded-pill font-size-11"
                        >{{ task.status }}</span
                        >
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Tasks</h4>

            <apexchart
              :options="tasksChart.chartOptions"
              :series="tasksChart.series"
              class="apex-charts"
              height="280"
              type="line"
            ></apexchart>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Recent Tasks</h4>

            <div class="table-responsive">
              <table class="table table-nowrap align-middle mb-0">
                <tbody>
                <tr>
                  <td>
                    <h5 class="text-truncate font-size-14 m-0">
                      <a class="text-dark" href="#">Brand logo design</a>
                    </h5>
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-4.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-5.jpg"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5 class="text-truncate font-size-14 m-0">
                      <a class="text-dark" href="#"
                      >Create a Blog Template UI</a
                      >
                    </h5>
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-1.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-2.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-3.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <div class="avatar-xs">
                              <span
                                class="
                                  avatar-title
                                  rounded-circle
                                  bg-info
                                  text-white
                                  font-size-16
                                "
                              >
                                D
                              </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5 class="text-truncate font-size-14 m-0">
                      <a class="text-dark" href="#"
                      >Redesign - Landing page</a
                      >
                    </h5>
                  </td>
                  <td>
                    <div class="avatar-group">
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-8.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            alt=""
                            class="rounded-circle avatar-xs"
                            src="@/assets/images/users/avatar-7.jpg"
                          />
                        </a>
                      </div>
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <div class="avatar-xs">
                              <span
                                class="
                                  avatar-title
                                  rounded-circle
                                  bg-danger
                                  text-white
                                  font-size-16
                                "
                              >
                                P
                              </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- end table responsive -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
